import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Header from "./header";
import Footer from "./footer";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #116ffd;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  -webkit-box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  -moz-box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #116ffd;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  -webkit-box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  -moz-box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ButtonLeft = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #116ffd;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  -webkit-box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  -moz-box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ButtonRight = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #116ffd;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  -webkit-box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  -moz-box-shadow: 4px 4px 2px -2px rgba(40, 40, 40, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;



function Home() {

  // Blockchain

  const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Quantity`);
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
      CONTRACT_ADDRESS: "",
      SCAN_LINK: "",
      NETWORK: {
        NAME: "",
        SYMBOL: "",
        ID: 0,
      },
      NFT_NAME: "",
      SYMBOL: "",
      MAX_SUPPLY: 1,
      WEI_COST: 0,
      DISPLAY_COST: 0,
      GAS_LIMIT: 0,
      MARKETPLACE: "",
      MARKETPLACE_LINK: "",
      SHOW_BACKGROUND: false,
    });
  
    const claimNFTs = () => {
      let cost = CONFIG.WEI_COST;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * mintAmount);
      let totalGasLimit = String(gasLimit * mintAmount);
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .mint(mintAmount)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong. Please refresh the page and try again.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `SUCCESS!! You can view your new mixtape at Opensea.io under Profile > Collected section.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
          if(document.body.contains(document.getElementById('mintcontrols'))){
            document.getElementById("mintcontrols").style.display = "none";
        }
  
  
        });
    };
  
    const decrementMintAmount = () => {
      let newMintAmount = mintAmount - 1;
      if (newMintAmount < 1) {
        newMintAmount = 1;
      }
      setMintAmount(newMintAmount);
    };
  
    const incrementMintAmount = () => {
      let newMintAmount = mintAmount + 1;
      if (newMintAmount > 10) {
        newMintAmount = 10;
      }
      setMintAmount(newMintAmount);
    };
  
    const getData = () => {
      if (blockchain.account !== "" && blockchain.smartContract !== null) {
        dispatch(fetchData(blockchain.account));
      }
    };
  
    const getConfig = async () => {
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const config = await configResponse.json();
      SET_CONFIG(config);
    };
  
    useEffect(() => {
      getConfig();
    });
  
    useEffect(() => {
      getData();
    }, [blockchain.account]);
  

  
  // Set the date we're counting down to
  var countDownDate = new Date("2021-11-05T20:00:00Z").getTime();

  // Update the count down every 1 second
  // var x = setInterval(function() {

  //     // Get today's date and time
  //     var now = new Date().getTime();

  //     // Find the distance between now and the count down date
  //     var distance = countDownDate - now;

  //     // Time calculations for days, hours, minutes and seconds
  //     var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     // Display the result in the element with id="timer"

  //     document.getElementById("day").innerHTML = days;
  //     document.getElementById("hour").innerHTML = hours;
  //     document.getElementById("minute").innerHTML = minutes;
  //     document.getElementById("second").innerHTML = seconds;

  //     // If the count down is finished, write some text
  //     if (distance < 0) {
  //       clearInterval(x);
  //       document.getElementById("timer").innerHTML = "-";
  //       }
  //   }, 1000);

  return (
    <>
    <main class="flex-shrink-0 pb-5">

    <div class="bg4">
             <div class="container py-4">
               <div class="row justify-content-center">
                 <div class="col-10 text-center pb-4">

        
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? 
                      (
                            <>
                            
                                
                            <div>{CONFIG.NFT_NAME} are now available on <a href="{CONFIG.MARKETPLACE_LINK}" target="_blank">{CONFIG.MARKETPLACE}</a></div>
                              

                            </>
                      ) : 
                      (
                        <>
                              
                                
                              {blockchain.account === "" ||
                                blockchain.smartContract === null ? 
                                (<>

                                  <div class="fs-4 py-4 color-change"><b>*MINTING NOW*</b></div>        
                                  <div class="fs-4 py-4">Please connect your MetaMask wallet</div>
                                    
                                  <StyledButton
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(connect());
                                      getData();
                                    }}
                                  >
                                    CONNECT WALLET
                                  </StyledButton>

                                  
                                  {blockchain.errorMsg !== "" ? 
                                  (
                                    <>
                                      <div>{blockchain.errorMsg}</div>
                                      
                                    </>
                                  ) : null}
                                 

                                </>
                                ) : 
                                (
                                  <>
                                  <div class="fs-3 py-4 color-change fw-bold">{CONFIG.MAX_SUPPLY / 10 - data.totalSupply} mixtapes left..</div>
                                  <div class="fs-4 py-3">{feedback}</div>
                                  <div id="mintcontrols">
                                    <div class="row py-3">
                                      <div class="col-5">                     
                                          <ButtonLeft
                                            style={{ lineHeight: 0.4 }}
                                            disabled={claimingNft ? 1 : 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              decrementMintAmount();
                                            }}
                                          >
                                            -
                                          </ButtonLeft>
                                        </div>
                                        <div class="col-2 fs-3">{mintAmount}</div>
                                          
                                        <div class="col-5">  
                                          <ButtonRight
                                            disabled={claimingNft ? 1 : 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              incrementMintAmount();
                                            }}
                                          >
                                            +
                                          </ButtonRight>
                                        </div>
                                      </div>
                                      <div class="py-3">
                                        <StyledButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTs();
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "PLEASE WAIT.." : "MINT"}
                                        </StyledButton>
                                        <div class="fs-6 py-3">
                              1 {CONFIG.SYMBOL} = {CONFIG.DISPLAY_COST}{" "}
                                {CONFIG.NETWORK.SYMBOL}

                              </div>
                                        
                                      </div>
                                  </div>
                                  
                                    </>
                                )
                              }
                        </>
                      )
                  }
                </div>
              </div>
             </div>
            </div>
  



      <div class="bg4 d-none">
        <div class="container py-4">
            <div class="row justify-content-center">
              <div class="col-10 text-center pb-4 fs-4">
                <p>NFT Pre-Sale</p>
                <p>Nov 5th 20:00 UTC Time</p>
                
                <div class="container timerbox">
                  
                  <div class="row fs-1 timerrow">
                    <div class="col">
                      <p id="day"></p>
                    </div>
                    <div class="col">
                      <p id="hour"></p>
                    </div>
                    <div class="col">
                      <p id="minute"></p>
                    </div>
                    <div class="col">
                      <p id="second"></p>
                    </div>
                  </div>
                  <div class="row fs-6">
                    <div class="col">
                        <p>day</p>
                    </div>
                    <div class="col">
                        <p>hour</p>
                    </div>
                    <div class="col">
                        <p>min</p>
                    </div>
                    <div class="col">
                        <p>sec</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="bg1">
          <div class="container">
            <div id="carouselExampleControls" class="carousel slide mixmedia  mx-auto" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="/config/images/1_copy.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                  <img src="/config/images/2_copy.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                  <img src="/config/images/3_copy.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                  <img src="/config/images/4_copy.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                  <img src="/config/images/5_copy.png" class="d-block w-100" />
                </div>
                <div class="carousel-item">
                  <img src="/config/images/6_copy.png" class="d-block w-100" />
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
     
      <div class="bg1">
        <div class="container">
          <div class="fs-4 pt-4">A mixtape is so much more than a compilation of various songs. 
            There’s blood, sweat and tears involved in making a mixtape. One could take hours, maybe even weeks to finish. 
            <br/><br/>
            There would be painful minutes spent starting and stopping and restarting a song in an attempt to hit the record button at just the right time. 
            Skipped songs, ruined tapes, punched holes in the walls. 
            <br/><br/>But once a perfect mixtape is created, it has the potential to change the world. 
            There are mixtapes that can fix broken relationships, save sinking businesses and even end wars. 
            <br/><br/>
            <div class="text-center">
              <img class="thumb" src="./config/images/thrift.png"></img>
            </div>
            <br/>Our story began when we found a box full of mixtapes at a thrift store. Each mixtape has a unique label and features exclusive tracks from emerging musical artists. And for some reason these mixtapes are very sticky. 
             We are more than happy to distribute these mixtapes to the music lovers and let them listen to these amazing tracks.      
            <br/><br/>When you own a sticky mixtape (as an NFT in your wallet), you will be able to connect to our Bouncy Boombox using your MetaMask wallet and listen to the exclusive tracks in your mixtape.
            <br/><br/> Make sure to get a sticky mixtape during our public sale on Friday Nov 5th before it's too late.
            <br/><br/><br/>
          </div>
        </div>
      </div>
      <br/>
    </main>
  </>);
}


// export default App;

export default function BasicExample() {
  return (
    <Router>
      <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/roadmap">
            <Roadmap />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/bouncy-boombox">
            <Boombox />
          </Route>
          <Route path="/mint">
            <Mint />
          </Route>
        </Switch>
        <Footer />
    </Router>
  );
}



function Roadmap() {
  return (
    <main class="flex-shrink-0 pb-5">
    <div class="bg1">
      <div class="container fs-4 py-4">
        <h1>Roadmap</h1>

        <div class="timeline">
          <div class="boxcontainer left">
            <div class="content">
              <h2>Q4 2021</h2>
              <p>Sticky mixtapes NFT Sale</p>
            </div>
          </div>
          <div class="boxcontainer right">
            <div class="content">
              <h2>Q1 2022</h2>
              <p>Musicians sign up for the Bouncy Boombox program and submit their tracks</p>
            </div>
          </div>
          <div class="boxcontainer left">
            <div class="content">
              <h2>Q2 2022</h2>
              <p>The Bouncy Boombox is live!</p>
            </div>
          </div>
        </div>


        <h1 class="pt-5">Project Details</h1>

        <ul class="pt-2">
          <li class="pt-2">
            20 mixtapes are pre-minted by our team to be distributed as giveaways and rewards.
          </li>
          <li class="pt-2">
            30% of sale proceeds will be used in the <a href="./bouncy-boombox">Bouncy Boombox</a> development.
          </li>
          <li class="pt-2">
            40% of sale proceeds will be distributed to the musicians that sign up for the Bouncy Boombox program. Musicians will also receive 2% commission on the resale of their mixtapes.
          </li>
          <li class="pt-2">
            20% of sale proceeds will be used to support our daily operations.
          </li>
          <li class="pt-2">
            10% of sale proceeds will be donated to the <a href="https://www.nammfoundation.org/" target="_blank">NAMM Foundation.</a>
          </li>
        </ul>


        <h1 class="pt-5">Perks</h1>

        <ul class="pt-2">
          <li class="pt-2">
            Top 5 people who invite the most people to our discord channel will be rewarded a Sticky Mixtape.
          </li>
          <li class="pt-2">
            If you want to become one of our social media ambassadors, please send us a DM in Twitter. Each ambassador will get a free Sticky Mixtape.
          </li>
        </ul>

        <h1 class="pt-5">Credits</h1>

        <ul class="pt-2">
          <li class="pt-2">
            Special thanks to Justin Werner, Lance H., dialZer0 and Taylor B. for their contribution to this project.
          </li>
        </ul>


        <div class="pt-4 fw-bold">
          Questions?
        </div>
        <div>
          Please join our <a href="https://discord.gg/GfBGtDD7P4" target="_blank">Discord</a>.
        </div>
        
      </div>
    </div>
  </main>
  );
}

function Faq() {
  return (
    <>
    <main class="flex-shrink-0 pb-5">
      <div class="bg1">
        <div class="container fs-5 py-4">
          <h1>FAQ</h1>
          <div class="pt-4 fw-bold">
            How many sticky mixtapes are available?
          </div>
          <div>
            There are only 1,000 sticky mixtapes.
          </div>
          <div class="pt-4 fw-bold">
            Why are they sticky?
          </div>
          <div>
            We really don't know. And we don't wanna know. Next question.
          </div>
          <div class="pt-4 fw-bold">
            How can I get a mixtape?
          </div>
          <div>
            Please make sure MetaMask is installed on your device (laptop or smartphone). Please read this <a href="https://help.foundation.app/en/articles/4731452-a-complete-guide-to-getting-eth-and-a-wallet-with-metamask">guide</a> on how to install MetaMask. 
            <br/><br/>During our public sale on Friday, Nov 5th, you will be able to purchase a mixtape by simply clicking the "Mint" button. Please make sure to have enough ETH in your MetaMask wallet before the sale begins.
          </div>
          <div class="pt-4 fw-bold">
            How many mixtapes can I buy?
          </div>
          <div>
            You can buy max 10 sticky mixtapes from each wallet.  
          </div>
          <div class="pt-4 fw-bold">
            Ok I got a mixtape, now what?
          </div>
          <div>
            Go to Opensea.io, connect with your MetaMask wallet, and check out "My Collection" section. You will see the sticky mixtape you purchased. Voilà!
          </div>
          <div class="pt-4 fw-bold">
            What else can I do with my mixtape?
          </div>
          <div>
            Great question! Once the <a href="./bouncy-boombox">Bouncy Boombox</a> is ready, you will be able to connect with your MetaMask wallet (that has a mixtape) and listen to the tracks in that mixtape on our platform. 
          </div>
          <div class="pt-4 fw-bold">
            What kind of tracks are in my mixtape?
          </div>
          <div>
            Your mixtape will feature exclusive tracks that are submitted from emerging musical artists. How cool is that! 
          </div>
          <div class="pt-4 fw-bold">
            More questions?
          </div>
          <div>
          Please join our <a href="https://discord.gg/GfBGtDD7P4" target="_blank">Discord</a>.
          </div>
          
        </div>
      </div>
    </main>
    </>
  );
}

function Boombox() {
  return (
    <>
    <main class="flex-shrink-0 pb-5">
      <div class="bg1">
        <div class="container py-4">

          <div class="fs-3 pt-4 fw-bold text-center">
            <div>
            Coming soon.
            </div>
          <img alt="logo" class="boombox" src="/config/images/bouncy-boombox.gif" />
            
          </div>

          
        </div>
      </div>
    </main>
    </>
  );
}

  function Mint() {

    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Quantity`);
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
      CONTRACT_ADDRESS: "",
      SCAN_LINK: "",
      NETWORK: {
        NAME: "",
        SYMBOL: "",
        ID: 0,
      },
      NFT_NAME: "",
      SYMBOL: "",
      MAX_SUPPLY: 1,
      WEI_COST: 0,
      DISPLAY_COST: 0,
      GAS_LIMIT: 0,
      MARKETPLACE: "",
      MARKETPLACE_LINK: "",
      SHOW_BACKGROUND: false,
    });
  
    const claimNFTs = () => {
      let cost = CONFIG.WEI_COST;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * mintAmount);
      let totalGasLimit = String(gasLimit * mintAmount);
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .mint(mintAmount)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong. Please refresh the page and try again.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `SUCCESS!! You can view your new mixtape at Opensea.io under Profile > Collected section.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
          if(document.body.contains(document.getElementById('mintcontrols'))){
            document.getElementById("mintcontrols").style.display = "none";
        }
  
  
        });
    };
  
    const decrementMintAmount = () => {
      let newMintAmount = mintAmount - 1;
      if (newMintAmount < 1) {
        newMintAmount = 1;
      }
      setMintAmount(newMintAmount);
    };
  
    const incrementMintAmount = () => {
      let newMintAmount = mintAmount + 1;
      if (newMintAmount > 10) {
        newMintAmount = 10;
      }
      setMintAmount(newMintAmount);
    };
  
    const getData = () => {
      if (blockchain.account !== "" && blockchain.smartContract !== null) {
        dispatch(fetchData(blockchain.account));
      }
    };
  
    const getConfig = async () => {
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const config = await configResponse.json();
      SET_CONFIG(config);
    };
  
    useEffect(() => {
      getConfig();
    });
  
    useEffect(() => {
      getData();
    }, [blockchain.account]);
  


    return (
      <>
      <main class="flex-shrink-0 pb-5">
        <div class="bg1">
          <div class="container py-4">
  
            <div class="bg4">
             <div class="container py-4">
               <div class="row justify-content-center">
                 <div class="col-10 text-center pb-4">

        
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? 
                      (
                            <>
                            
                                
                            <div>{CONFIG.NFT_NAME} are now available on <a href="{CONFIG.MARKETPLACE_LINK}" target="_blank">{CONFIG.MARKETPLACE}</a></div>
                              

                            </>
                      ) : 
                      (
                        <>
                              
                                
                              {blockchain.account === "" ||
                                blockchain.smartContract === null ? 
                                (<>

                                  <div class="fs-4 py-4 color-change"><b>*MINTING NOW*</b></div>        
                                  <div class="fs-4 py-4">Please connect your MetaMask wallet</div>
                                    
                                  <StyledButton
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(connect());
                                      getData();
                                    }}
                                  >
                                    CONNECT WALLET
                                  </StyledButton>

                                  
                                  {blockchain.errorMsg !== "" ? 
                                  (
                                    <>
                                      <div>{blockchain.errorMsg}</div>
                                      
                                    </>
                                  ) : null}
                                 

                                </>
                                ) : 
                                (
                                  <>
                                  <div class="fs-3 py-4 color-change fw-bold">{CONFIG.MAX_SUPPLY / 10 - data.totalSupply} mixtapes left..</div>
                                  <div class="fs-4 py-3">{feedback}</div>
                                  <div id="mintcontrols">
                                    <div class="row py-3">
                                      <div class="col-5">                     
                                          <ButtonLeft
                                            style={{ lineHeight: 0.4 }}
                                            disabled={claimingNft ? 1 : 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              decrementMintAmount();
                                            }}
                                          >
                                            -
                                          </ButtonLeft>
                                        </div>
                                        <div class="col-2 fs-3">{mintAmount}</div>
                                          
                                        <div class="col-5">  
                                          <ButtonRight
                                            disabled={claimingNft ? 1 : 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              incrementMintAmount();
                                            }}
                                          >
                                            +
                                          </ButtonRight>
                                        </div>
                                      </div>
                                      <div class="py-3">
                                        <StyledButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTs();
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "PLEASE WAIT.." : "MINT"}
                                        </StyledButton>
                                        <div class="fs-6 py-3">
                              1 {CONFIG.SYMBOL} = {CONFIG.DISPLAY_COST}{" "}
                                {CONFIG.NETWORK.SYMBOL}

                              </div>
                                        
                                      </div>
                                  </div>
                                  
                                    </>
                                )
                              }
                        </>
                      )
                  }
                </div>
              </div>
             </div>
            </div>
  
            
          </div>
        </div>
      </main>
      </>
    );

}