import React from "react";
import { NavLink } from "react-router-dom";


function Header() {
  return (
        <header>
            <nav class="navbar navbar-light fixed-top-revised">
                <div class="container-fluid">
                    <div class="navbar-brand mx-auto"></div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div class="offcanvas-header">
                        
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3 fs-3">
                                <li class="nav-item py-3">
                                <a class="nav-link active" href="/">Home</a>
                                </li>
                                <li class="nav-item py-3">
                                <a class="nav-link active" href="/faq">FAQ</a>
                                </li>
                                <li class="nav-item py-3">
                                <a class="nav-link active" href="/roadmap">Roadmap</a>
                                </li>
                                <li class="nav-item py-3">
                                <a class="nav-link active" href="/bouncy-boombox">Bouncy Boombox</a>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </nav>
            <div class="logo pb-3"><a href="/"><img alt="logo" class="sitelogo bg-white" src="/config/images/logo.png" /></a></div>
        </header>
  );
}
export default Header;